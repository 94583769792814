<template>
  <div id="app">
    <DataSearch />
  </div>
</template>

<script>
import DataSearch from './components/DataSearch.vue'

export default {
  name: 'App',
  components: {
    DataSearch
  }
}
</script>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primaryx: #0699de;
$primary: #4ca1d7;
$primary-invert: findColorInvert($primary);
$primelight: #6fc3f7;
$primelight-invert: findColorInvert($primary);
$violet: hsl(273, 32%, 46%);
$violet-invert: findColorInvert($grey);
$kin: #22324c;
$kin-invert: findColorInvert($kin);

$analyzed: #e9ffd1;
$analyzed-invert: findColorInvert($analyzed);

$await: #ffbb00;
$await-invert: findColorInvert($await);

$purple: #4747d1;
$purple-invert: findColorInvert($purple);
$teal: #0099cc;
$teal-invert: findColorInvert($teal);
$maroon: #7a03b1;
$maroon-invert: findColorInvert($maroon);
$orange: #e68a00;
$orange-invert: findColorInvert($orange);
$forest: #006600;
$forest-invert: findColorInvert($forest);

$input-background-color: #f2fafd;
$input-shadow: none;
$input-border-color: #d6d6d6;
$box-radius: 10px;

$toast-opacity: 1;

$control-radius: 2px;
$radius: 1px;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: mergeColorMaps(
  (
    "white": (
      $white,
      $black,
    ),
    "black": (
      $black,
      $white,
    ),
    "light": (
      $light,
      $light-invert,
    ),
    "dark": (
      $dark,
      $dark-invert,
    ),
    "primary": (
      $primary,
      $primary-invert,
      $primary-light,
      $primary-dark,
    ),
    "link": (
      $link,
      $link-invert,
      $link-light,
      $link-dark,
    ),
    "info": (
      $info,
      $info-invert,
      $info-light,
      $info-dark,
    ),
    "success": (
      $success,
      $success-invert,
      $success-light,
      $success-dark,
    ),
    "warning": (
      $warning,
      $warning-invert,
      $warning-light,
      $warning-dark,
    ),
    "danger": (
      $danger,
      $danger-invert,
      $danger-light,
      $danger-dark,
    ),
    "kin": (
      $kin,
      $kin-invert,
    ),
  ),
  $custom-colors
);

// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

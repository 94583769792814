/**
 * General APIs configuration.
 */

const gateway = false

export const SERVICE_PATIENT = gateway ? 'https://pgw.digitmed.pl' : 'https://patienttest.digitmed.pl'

export const Apis = {

    PATIENT_GET_RESULT: SERVICE_PATIENT + '/api/Patient/GetData',
    PATIENT_GET_FILE: SERVICE_PATIENT + '/api/Patient/GetFile',

}
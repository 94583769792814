<template>
  <div>
    <!---------------------------------------------------------------------
    -----------------------------------------------------------------------
    -----------------------DESKTOP VERSION---------------------------------
    -----------------------------------------------------------------------
    ---------------------------------------------------------------------->
    <div v-if="st == 0 && !isMobile" style="margin-top: 60px">
      <b-icon
        class="is-centered primary-color"
        icon="heart-pulse"
        size="is-large"
        type="is-primary"
        style="padding-bottom: 30px; margin-top: 20px"
      ></b-icon>
      <div class="columns is-centered mt-5">
        <div class="column is-3 pr-6" style="border-right: 1px solid #4ca1d7">
          <p class="subtitle has-text-info pb-3" style="font-size: 1.5rem">
            Pojedyncze zlecenie
          </p>
          <p style="font-size: 0.8rem">
            Wprowadź numer zlecenia oraz PESEL lub numer dokumentu, aby otrzymać
            dostęp do wyników badań.
          </p>
          <b-tooltip position="is-bottom" class="primary-color mb-5">
            <p class="primary-color" style="font-size: 0.8rem">
              Czym jest numer zlecenia?
            </p>
            <template v-slot:content style="text-align: justify">
              <b>Numer zlecenia</b> - jest to numer znajdujący się
              <p>na etykiecie otrzymanej w punkcie pobrań</p>
            </template>
          </b-tooltip>
          <b-field label="Numer zlecenia" label-position="on-border">
            <b-input :disabled="!isRegulationsAccepted" v-model="id"></b-input>
          </b-field>
          <b-field label="PESEL/Numer dokumentu" label-position="on-border">
            <b-input
              :disabled="!isRegulationsAccepted"
              v-model="pesel"
            ></b-input>
          </b-field>
          <b-button
            @click="getResult"
            type="is-info"
            :loading="loading"
            :disabled="
              id.length < 1 || pesel.length < 1 || !isRegulationsAccepted
            "
            rounded
            >Sprawdź wyniki</b-button
          >

          <div>
            <b-tooltip
              position="is-bottom"
              class="primary-color mb-0 mt-5"
              multilined
            >
              <p
                class="primary-color"
                style="font-size: 0.8rem; cursor: pointer"
                @click="isModalTermsActive = true"
                v-if="isRegulationsAccepted"
              >
                Regulamin usługi
              </p>
              <p
                class="has-text-danger"
                style="font-size: 0.8rem; cursor: pointer"
                @click="isModalTermsActive = true"
                v-else
              >
                Zaakceptuj regulamin, aby uzyskać dostęp do wyników
              </p>
              <template v-slot:content
                >Kliknij, aby przejść do regulaminu</template
              >
            </b-tooltip>
          </div>
          <div>
            <b-tooltip
              position="is-bottom"
              class="primary-color mb-0 mt-1"
              multilined
            >
              <p
                class="primary-color"
                style="font-size: 0.8rem; cursor: pointer"
                @click="isModalInstructionActive = true"
              >
                Instrukcja odbioru wyników
              </p>
              <template v-slot:content
                >Kliknij, aby przejść do instrukcji</template
              >
            </b-tooltip>
          </div>
          <!-- <div>
            <b-tooltip
              position="is-bottom"
              class="primary-color mb-0 mt-1"
              multilined
            >
              <p
                class="primary-color"
                style="font-size: 0.8rem; cursor: pointer"
              >
                Najczęściej zadawane pytania
              </p>
              <template v-slot:content> Kliknij, aby przejść do FAQ </template>
            </b-tooltip>
          </div>-->
        </div>
        <div class="column is-3 pl-6">
          <p class="subtitle pb-3 has-text-primary" style="font-size: 1.5rem">
            Konto pacjenta
          </p>
          <p style="font-size: 0.8rem" class="mb-6">
            <!-- Możliwość skorzystania z konta pacjenta zostanie udostępniona
            wkrótce. Aby sprawdzić wynik, skorzystaj z opcji "Pojedyncze
            zlecenia". -->
            Jeśli posiadasz konto pacjenta wprowadź PESEL/login i hasło.
          </p>
          <b-field label="PESEL/Login" label-position="on-border">
            <b-input v-model="login"></b-input>
          </b-field>

          <b-field label="Hasło" label-position="on-border">
            <b-input
              v-model="pass"
              custom-class="color-primary"
              type="password"
              expanded
              icon="key"
              password-reveal
            ></b-input>
          </b-field>

          <b-button
            :disabled="!pass || !login"
            type="is-info"
            @click="openPatientPortal"
            rounded
            >Zaloguj</b-button
          >
        </div>
      </div>
      <footer class="has-text-centered pt-6">
        <small>
          <b-tooltip label="System Zarządzania Opieką Koordynowaną">
            <span class="has-text-dark mr-3">
              <b-icon
                icon="heart-pulse"
                type="is-primary"
                size="is-small"
              ></b-icon>
              <strong class="has-text-primary">SZOK-LAB</strong>
              by Digitmed
            </span>
          </b-tooltip>
        </small>
        <div class="is-clearfix"></div>
      </footer>
    </div>
    <div
      v-if="st == 1 && !isMobile"
      class="fluid page-pad has-background-kin mb-6"
      style="background-color: #22324c !important"
    >
      <div class="level has-text-centered" style="width: 90%; margin: 0 auto">
        <div class="level-left pl-6">
          <div class="level-item">
            <span
              class="hand has-text-primary"
              style="margin-top: 1px; padding: 0.4rem"
            >
              <b-icon
                icon="heart-pulse"
                size="is-medium"
                type="is-primelight"
              ></b-icon>
            </span>
            <h3
              class="has-text-weight-medium has-text-primary"
              style="
                margin-top: 3px;
                margin-left: 0.3rem;
                margin-bottom: 0.25rem;
                margin-right: 0.5rem;
              "
            >
              System Zarządzania Opieką Koordynowaną
              <span class="has-text-weight-medium has-text-light fs-09"
                >- wyniki badań laboratoryjnych</span
              >
            </h3>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <span>
              <b-tooltip
                label="Wyloguj"
                position="is-bottom"
                type="is-danger"
                class="mr-6 hand"
              >
                <b-button @click="logout" size="is-small" type="is-text">
                  <b-icon
                    icon="exit-to-app"
                    type="is-danger"
                    class="vam"
                  ></b-icon>
                </b-button>
              </b-tooltip>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="st == 1 && !isMobile" style="margin: 0 auto' width: 90%">
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 90%;
          margin: 0 auto;
        "
        class="mt-3 pb-5"
      >
        <div style="margin-left: 7rem">
          <img src="../assets/logo_centrum.jpg" width="190" />
        </div>
        <div style="margin-right: 5rem; float: right">
          <p class="has-text-danger mt-5" style="font-size: 0.85rem">
            <b-icon icon="alert-circle" class="mr-1"></b-icon>Linki generowane
            do plików są jednorazowe - aby kolejny raz pobrać plik, należy
            ponownie się zalogować
          </p>
          <p class="mt-4 fs-09" style="float: right">
            <span class="has-text-primary"
              >Automatyczne wylogowanie nastąpi za:</span
            >
            <span :class="{ 'has-text-danger': cc < 60 }">{{ timer }}</span>
          </p>
        </div>
      </div>
      <!-- <p class="has-text-danger" style="font-size: 0.9rem">
        Linki generowane do plików są jednorazowe, aby kolejny raz pobrać plik
        należy ponownie się zalogować.
      </p>
      <p style="font-size: 0.9rem" class="mt-4">
        <span class="has-text-primary"
          >Automatyczne wylogowanie nastąpi za:
        </span>
        <span :class="{ 'has-text-danger': cc < 60 }">{{ timer }}</span>
      </p>-->
      <div style="width: 85%; margin: 0 auto">
        <div>
          <div class="columns">
            <div
              v-if="!result.order"
              class="column is-12"
              style="text-align: left"
            >
              <div>
                <div class="box m-5 px-6 pb-6 pt-3 fs-09">
                  <!--------------------------------------------------------------------------------------------------- download file -->
                  <div v-if="result" class="has-text-right mr-1 mt-3 mb-3">
                    <b-button
                      @click="getFile(result.pdfPlLink, pl)"
                      class="primary-color mr-2"
                      icon-left="download"
                      :loading="loading"
                      :disabled="!result.pdfPlLink"
                      size="is-small"
                      rounded
                      >Pobierz wynik (PDF)</b-button
                    >
                    <b-button
                      @click="getFile(result.pdfEnLink, eng)"
                      class="primary-color"
                      icon-left="download"
                      :loading="loading"
                      :disabled="!result.pdfEnLink"
                      size="is-small"
                      rounded
                      >Download result (PDF)</b-button
                    >
                    <!-- <b-button icon-right="logout" class="">Wyloguj</b-button> -->
                  </div>
                  <!-------------------------------------------------------------------------------------------------------------------->
                  <!--------------------------------------------------------------------------------------------------- download file -->
                  <table class="table" style="width: 100%">
                    <tr>
                      <td style="width: 20%" class="py-3">
                        <p>
                          <b>Numer zlecenia:</b>
                        </p>
                      </td>
                      <td v-if="result" colspan="2" class="py-3">
                        {{ result.sampleIntCode }}
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 20%" class="py-3">
                        <p>
                          <b>Badanie:</b>
                        </p>
                      </td>
                      <td colspan="2" class="py-3">
                        SARS-CoV-2 (COVID 19) met. Real Time RT-PCR (ICD-9: V99)
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 20%" class="py-3">
                        <p>
                          <b>Parametr:</b>
                        </p>
                      </td>
                      <td colspan="2" class="py-3">SARS-CoV-2</td>
                    </tr>
                    <tr>
                      <td style="width: 20%" class="py-3">
                        <p>
                          <b>Wynik badania:</b>
                        </p>
                      </td>
                      <td colspan="2" style="font-size: 0.95rem" class="py-3">
                        <b v-if="result.result">
                          <span
                            :class="{
                              'has-text-danger': result.result == 'POZYTYWNY',
                              'has-text-success': result.result == 'NEGATYWNY',
                            }"
                            >{{ result.result }}</span
                          >
                        </b>
                        <b v-else>-</b>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-3">
                        <p>
                          <b>Interpretacja wyniku:</b>
                        </p>
                      </td>
                      <td colspan="2" style="text-align: justify" class="py-3">
                        <p v-if="result.result">
                          {{ getResultDescription(result.result) }}
                        </p>
                        <p v-else>-</p>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-3">
                        <strong>Opis metody:</strong>
                      </td>
                      <td colspan="2" style="text-align: justify" class="py-3">
                        <p>
                          Przeprowadzono łańcuchową reakcję polimerazy w czasie
                          rzeczywistym (Real-Time RT-PCR) w obecności starterów
                          i sond, komplementarnych do dwóch niezależnych
                          fragmentów genomu koronawirusa SARS-CoV-2 (gen ORF 1ab
                          oran gen N). Jako matrycy użyto RNA wyizolowanego z
                          materiału pobranego od pacjenta. Czułość metody: 100
                          kopii wirusa/ml. Interpretacji wyniku badania powinien
                          dokonać lekarz. Wynik badania nie wyklucza obecności
                          innych patogenów chorobotwórczych w badanym materiale.
                          Badanie przeprowadzono z zastosowaniem testu
                          SARS-CoV-2 RealTime PCR Bioer Quant Gene 9600.
                        </p>
                      </td>
                    </tr>
                  </table>
                  <!-------------------------------------------------------------------------------------------------------------------->
                </div>
              </div>
            </div>
            <!-------------------------------------------------------------------------------------------------------------------->
            <div
              v-else-if="
                result &&
                result.order &&
                result.order.results &&
                result.order.results.length > 0
              "
              class="column is-12"
              style="text-align: left"
            >
              <div
                v-for="(item, item_index) in result.order.results"
                :key="item_index"
                class="mb-5"
              >
                <div class="box m-5 px-6 pb-6 pt-3 fs-09">
                  <!--********************************************************************************************************************** FILE DOWNLOAD *-->
                  <div v-if="result" class="has-text-right mr-1 mt-3 mb-1">
                    <b-button
                      @click="getFile(item.pdfPlLink, pl)"
                      :loading="loading"
                      :disabled="!item.pdfPlLink"
                      icon-left="download"
                      type="is-primary"
                      size="is-small"
                      outlined
                      rounded
                    >
                      <!-- <b-icon icon="download" class="mr-1 vam"></b-icon> -->
                      Pobierz wynik (PDF)
                    </b-button>
                    <b-button
                      v-if="item.material == 1"
                      @click="getFile(item.pdfEnLink, eng)"
                      class="ml-2"
                      icon-left="file-document"
                      :loading="loading"
                      :disabled="!item.pdfEnLink"
                      >Download result (PDF)</b-button
                    >
                  </div>
                  <!--**************************************************************************************************************************************-->
                  <table class="table" style="width: 100%">
                    <tr>
                      <td style="width: 20%" class="py-3">
                        <p>
                          <b>Nazwa badania:</b>
                        </p>
                      </td>
                      <td colspan="2" class="py-3">
                        <span v-if="item.material == 1">
                          SARS-CoV-2 (COVID 19) met. Real Time RT-PCR (ICD-9:
                          V99)
                        </span>
                        <span v-if="item.material == 2">
                          Badanie cytologiczne szyjki macicy -
                          <span v-if="item.cytoExaminationType == 0"
                            >cytologia konwencjonalna</span
                          >
                          <span v-else-if="item.cytoExaminationType == 1"
                            >cytologia LBC</span
                          >
                        </span>
                        <span v-if="item.material == 3"
                          >Wirus brodawczaka ludzkiego (HPV) HPV-DNA</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 20%" class="py-3">
                        <p>
                          <b>Numer:</b>
                        </p>
                      </td>
                      <td colspan="2" class="py-3">{{ item.sampleIntCode }}</td>
                    </tr>
                    <tr>
                      <td style="width: 20%" class="py-3">
                        <p>
                          <b>Wynik:</b>
                        </p>
                      </td>
                      <td colspan="2" style="font-size: 0.95rem" class="py-3">
                        <b v-if="item.result">{{ item.result }}</b>
                        <b v-else>brak (badanie w trakcie realizacji)</b>
                      </td>
                    </tr>
                  </table>
                  <!-------------------------------------------------------------------------------------------------------------------->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="has-text-centered pt-4">
        <small>
          <b-tooltip label="System Zarządzania Opieką Koordynowaną">
            <span class="has-text-dark mr-3">
              <b-icon
                icon="heart-pulse"
                type="is-primary"
                size="is-small"
              ></b-icon>
              <!-- <strong class="has-text-primary">SZOK</strong>
              by Digitmed-->
              <strong class="has-text-primary">SZOK-LAB</strong>
              by Digitmed
            </span>
          </b-tooltip>
        </small>
      </footer>
    </div>
    <!---------------------------------------------------------------------
    -----------------------------------------------------------------------
    ------------------------MOBILE VERSION---------------------------------
    -----------------------------------------------------------------------
    ---------------------------------------------------------------------->
    <div v-if="st == 0 && isMobile" style="margin-top: 30px">
      <b-icon
        class="is-centered primary-color"
        icon="heart-pulse"
        size="is-large"
        type="is-primary"
        style="padding-bottom: 30px; margin-top: 20px"
      ></b-icon>
      <div class="mt-2">
        <div class="px-6" style="border-right: 1px solid #4ca1d7">
          <p class="subtitle has-text-info pb-3" style="font-size: 1.3rem">
            Pojedyncze zlecenie
          </p>
          <p style="font-size: 0.8rem">
            Wprowadź numer zlecenia oraz PESEL lub numer dokumentu, aby otrzymać
            dostęp do wyników badań.
          </p>
          <b-tooltip position="is-bottom" class="primary-color mb-5" multilined>
            <p class="primary-color" style="font-size: 0.8rem">
              Czym jest numer zlecenia?
            </p>
            <template v-slot:content>
              <b>Numer zlecenia</b> jest to numer znajdujący się na ulotce
              otrzymanej w punkcie pobrań.
            </template>
          </b-tooltip>
          <b-field label="Numer zlecenia" label-position="on-border">
            <b-input v-model="id" :disabled="!isRegulationsAccepted"></b-input>
          </b-field>
          <b-field label="PESEL/Numer dokumentu" label-position="on-border">
            <b-input
              v-model="pesel"
              :disabled="!isRegulationsAccepted"
            ></b-input>
          </b-field>
          <b-button
            @click="getResult"
            type="is-info"
            :loading="loading"
            :disabled="
              id.length < 1 || pesel.length < 1 || !isRegulationsAccepted
            "
            rounded
            >Sprawdź wyniki</b-button
          >
          <div>
            <b-tooltip
              position="is-bottom"
              class="primary-color mb-0 mt-5"
              multilined
            >
              <p
                v-if="isRegulationsAccepted"
                class="primary-color"
                style="font-size: 0.8rem; cursor: pointer"
                @click="isModalTermsActive = true"
              >
                Regulamin usługi
              </p>
              <p
                v-else
                class="has-text-danger"
                style="font-size: 0.8rem; cursor: pointer"
                @click="isModalTermsActive = true"
              >
                Zaakceptuj regulamin, aby uzyskać dostęp do wyników
              </p>
              <template v-slot:content
                >Kliknij, aby przejść do regulaminu</template
              >
            </b-tooltip>
          </div>
          <div>
            <b-tooltip
              position="is-bottom"
              class="primary-color mb-0 mt-1"
              multilined
            >
              <p
                class="primary-color"
                style="font-size: 0.8rem; cursor: pointer"
                @click="isModalInstructionActive = true"
              >
                Instrukcja odbioru wyników
              </p>
              <template v-slot:content
                >Kliknij, aby przejść do instrukcji</template
              >
            </b-tooltip>
          </div>
          <!-- <div>
            <b-tooltip
              position="is-bottom"
              class="primary-color mb-0 mt-1"
              multilined
            >
              <p
                class="primary-color"
                style="font-size: 0.8rem; cursor: pointer"
              >
                Najczęściej zadawane pytania
              </p>
              <template v-slot:content> Kliknij, aby przejść do FAQ </template>
            </b-tooltip>
          </div>-->
        </div>
        <hr />
        <div class="px-6">
          <p class="subtitle pb-3 mt-5" style="font-size: 1.3rem; color: grey">
            Konto pacjenta
          </p>
          <p style="font-size: 0.8rem" class="mb-6">
            Możliwość skorzystania z konta pacjenta zostanie udostępniona
            wkrótce. Aby sprawdzić wynik, skorzystaj z opcji "Pojedyncze
            zlecenia".
          </p>
          <b-field label="PESEL/Login" label-position="on-border">
            <b-input disabled v-model="login"></b-input>
          </b-field>
          <b-field label="Hasło" label-position="on-border">
            <b-input
              disabled
              v-model="pass"
              custom-class="has-text-centered color-primary"
              type="password"
              expanded
              icon="key"
              password-reveal
            ></b-input>
          </b-field>
          <b-button type="is-info" class="mb-6" disabled>Zaloguj</b-button>
        </div>
      </div>
      <footer class="has-text-centered pt-4">
        <small>
          <b-tooltip label="System Zarządzania Opieką Koordynowaną">
            <span class="has-text-dark mr-3">
              <b-icon
                icon="heart-pulse"
                type="is-primary"
                size="is-small"
              ></b-icon>
              <!-- <strong class="has-text-primary">SZOK</strong>
              by Digitmed-->
              <strong class="has-text-primary">SZOK-LAB</strong>
              by Digitmed
            </span>
          </b-tooltip>
        </small>
      </footer>
    </div>
    <div
      v-if="st == 1 && isMobile"
      class="fluid page-pad has-background-kin mb-3"
      style="background-color: #22324c !important"
    >
      <div class="level">
        <div class="level-left pl-1">
          <div class="level-item">
            <span
              class="hand has-text-primary"
              style="margin-top: 1px; padding: 0.2rem"
            >
              <b-icon
                icon="heart-pulse"
                size="is-medium"
                type="is-primelight"
              ></b-icon>
            </span>
            <h3
              class="has-text-weight-semibold"
              style="
                margin-top: 3px;
                margin-left: 0.9rem;
                margin-bottom: 0.25rem;
                margin-right: 0.5rem;
              "
            >
              <span class="has-text-primary">SZOK-LAB</span>
              <span class="has-text-light" style="font-weight: 400"
                >- wyniki badań laboratoryjnych</span
              >
            </h3>
            <span @click="logout" class="pr-1">
              <b-icon icon="exit-to-app" type="is-danger"></b-icon>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="st == 1 && isMobile" style="width: 90%; margin: 0 auto">
      <p style="font-size: 0.9rem" class="mb-3 mt-5 has-text-danger">
        <b-icon icon="alert-circle" class="mr-1"></b-icon>Generowane linki do
        plików są jednorazowe - aby kolejny raz pobrać plik, należy ponownie się
        zalogować.
      </p>
      <p style="font-size: 0.9rem" class="mb-3">
        <span class="has-text-primary"
          >Automatyczne wylogowanie nastąpi za:</span
        >
        <span :class="{ 'has-text-danger': cc < 60 }">{{ timer }}</span>
      </p>
      <div v-if="!result.order" class="box" style="text-align: left">
        <div v-if="result" class="has-text-right m-0 p-0">
          <b-button
            @click="getFile(result.pdfPlLink, pl)"
            class="mt-1 mb-1 mr-2"
            icon-left="download"
            :loading="loading"
            size="is-small"
            rounded
            >Pobierz wynik (PDF)</b-button
          >
          <b-button
            @click="getFile(result.pdfEnLink, eng)"
            class="mt-1 mb-4"
            icon-left="download"
            :loading="loading"
            size="is-small"
            rounded
            >Download result (PDF)</b-button
          >
        </div>
        <p class="fs-09" v-if="result">
          <b>Numer zlecenia:</b>
          {{ result.sampleIntCode }}
        </p>
        <p class="fs-09 mt-1">
          <b>Badanie:</b>
        </p>
        <p class="pl-3 fs-09 mt-1">
          SARS-CoV-2 (COVID 19) met. Real Time RT-PCR (ICD-9: V99)
        </p>
        <p class="fs-09 mt-1"><b>Parametr:</b> SARS-CoV-2</p>
        <p class="pl-3 fs-09 mt-1"></p>
        <p class="fs-09 mt-1">
          <b>Wynik:</b>
        </p>
        <p v-if="result" class="pl-3 fs-09 mt-1" style="text-align: justify">
          <b style="font-size: 1rem">{{ result.result }}</b>
        </p>
        <p class="pl-3 fs-09 mt-1"></p>
        <p class="fs-09 mt-1">
          <b>Interpretacja:</b>
        </p>
        <p v-if="result" class="pl-3 fs-09 mt-1" style="text-align: justify">
          {{ getResultDescription(result.result) }}
        </p>
        <p class="fs-09 mt-1">
          <b>Opis metody:</b>
        </p>
        <p class="pl-3 fs-09 mt-1" style="text-align: justify">
          Przeprowadzono łańcuchową reakcję polimerazy w czasie rzeczywistym
          (Real-Time RT-PCR) w obecności starterów i sond, komplementarnych do
          dwóch niezależnych fragmentów genomu koronawirusa SARS-CoV-2 (gen ORF
          1ab oran gen N). Jako matrycy użyto RNA wyizolowanego z materiału
          pobranego od pacjenta. Czułość metody: 500 kopii wirusa/ml.
          Interpretacji wyniku badania powinien dokonać lekarz. Wynik badania
          nie wyklucza obecności innych patogenów chorobotwórczych w badanym
          materiale. Badanie przeprowadzono z zastosowaniem testu SARS-CoV-2
          RealTime PCR Bioer Quant Gene 9600.
        </p>
      </div>

      <div
        v-else-if="
          result &&
          result.order &&
          result.order.results &&
          result.order.results.length > 0
        "
      >
        <div
          v-for="(item, item_index) in result.order.results"
          :key="item_index"
          class="mb-5 box"
          style="text-align: left"
        >
          <div v-if="result" class="has-text-right m-0 p-0">
            <b-button
              @click="getFile(item.pdfPlLink, pl)"
              class="mt-1 mb-5 mr-2"
              icon-left="download"
              :loading="loading"
              :disabled="!item.pdfPlLink"
              size="is-small"
              rounded
              >Pobierz wynik (PDF)</b-button
            >
            <b-button
              v-if="item.material == 1"
              @click="getFile(item.pdfEnLink, eng)"
              class="mt-1 mb-5"
              icon-left="download"
              :loading="loading"
              :disabled="!item.pdfEnLink"
              size="is-small"
              rounded
              >Download result (PDF)</b-button
            >
          </div>
          <p class="fs-09">
            <b>Nazwa badania:</b>
            <span v-if="item.material == 1"
              >SARS-CoV-2 (COVID 19) met. Real Time RT-PCR (ICD-9: V99)</span
            >
            <span v-if="item.material == 2">
              Badanie cytologiczne szyjki macicy -
              <span v-if="item.cytoExaminationType == 0"
                >cytologia konwencjonalna</span
              >
              <span v-else-if="item.cytoExaminationType == 1"
                >cytologia LBC</span
              >
            </span>
            <span v-if="item.material == 3"
              >Wirus brodawczaka ludzkiego (HPV) HPV-DNA</span
            >
          </p>
          <p class="fs-09 mt-1">
            <b>Numer:</b>
            {{ item.sampleIntCode }}
          </p>
          <p class="pl-3 fs-09 mt-1"></p>
          <p class="fs-09 mt-1">
            <b>Wynik:</b>
            <b v-if="item.result">{{ item.result }}</b>
            <b v-else>brak (badanie w trakcie realizacji)</b>
          </p>
        </div>
      </div>
      <footer class="has-text-centered pt-4">
        <small>
          <b-tooltip label="System Zarządzania Opieką Koordynowaną">
            <span class="has-text-dark mr-3">
              <b-icon
                icon="heart-pulse"
                type="is-primary"
                size="is-small"
              ></b-icon>
              <!-- <strong class="has-text-primary">SZOK</strong>
              by Digitmed-->
              <strong class="has-text-primary">SZOK-LAB</strong>
              by Digitmed
            </span>
          </b-tooltip>
        </small>
      </footer>
    </div>
    <div v-if="st == 2">
      <PatientPortalDashboard />
    </div>

    <b-modal
      :active.sync="isModalTermsActive"
      has-modal-card
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      :full-screen="isMobile"
      class="lab-results-regulations-modal"
    >
      <div class="modal-card">
        <header class="modal-card-head has-background-kin">
          <p
            v-if="!isMobile"
            class="modal-card-title has-text-primary"
            style="font-size: 1.1rem"
          >
            <b-icon
              icon="file-document-edit"
              class="mr-2 has-text-primary"
            ></b-icon
            >REGULAMIN ŚWIADCZENIA USŁUGI „WYNIKI BEZ REJESTRACJI” od dnia
            1.12.2020 r.
          </p>
          <div
            v-else
            class="modal-card-title has-text-primary"
            style="font-size: 1rem"
          >
            <p>REGULAMIN ŚWIADCZENIA USŁUGI</p>
            <p>„WYNIKI BEZ REJESTRACJI”</p>
            <p>od dnia 1.12.2020 r.</p>
          </div>
          <button
            type="button"
            class="delete"
            @click="isModalTermsActive = false"
          />
        </header>
        <section
          class="modal-card-body"
          style="text-align: justify; font-size: 0.9rem"
        >
          <p>
            <b>I. Postanowienia ogólne:</b>
          </p>
          <p>
            1. Na podstawie art. 8 ust. 1 pkt 1 ustawy z dnia 18 lipca 2002 r. o
            świadczeniu usług drogą elektroniczną: Centrum
            Medyczno-Diagnostyczne Spółka z ograniczoną odpowiedzialnością z
            siedzibą w Siedlcach ul. Niklowa 9, 08-110 Siedlce: wpisana do
            rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Lublin –
            Wschód w Lublinie z siedzibą w Świdniku, VI Wydział Gospodarczy
            Krajowego Rejestru Sądowego jako sąd, w którym przechowywana jest
            dokumentacja Spółki pod numerem KRS: 0000036205, NIP: 821-20-25-575,
            Regon: 710529892, Kapitał zakładowy: 700 500 zł. zwana dalej:
            „Centrum” lub „Usługodawcą”, wprowadza niniejszym Regulamin
            świadczenia usługi „Wyniki Bez rejestracji”, zwany dalej
            „Regulaminem”.
          </p>
          <p>
            2. Niniejszy Regulamin określa zasady i warunki techniczne
            świadczenia przez Centrum Medyczno-Diagnostyczne Spółka z
            ograniczoną odpowiedzialnością z siedzibą w Siedlcach Usługi „Wyniki
            Bez rejestracji” na rzecz Usługobiorców drogą elektroniczną, w
            szczególności za pośrednictwem strony internetowej pod adresem:
            www.pacjent.digitmed.pl.
          </p>
          <p>
            3. Każdy Usługobiorca zobowiązany jest do przestrzegania postanowień
            niniejszego Regulaminu od momentu podjęcia czynności zmierzających
            do skorzystania z Usługi „Wyniki bez rejestracji”.
          </p>
          <p>
            <b>II. Definicje:</b>
          </p>
          <p>
            a)
            <b>Centrum Medyczno-Diagnostyczne/Usługodawca</b> – Centrum
            Medyczno-Diagnostyczne Spółka z ograniczoną odpowiedzialnością z
            siedzibą w Siedlcach ul. Niklowa 9, 08-110 Siedlce: wpisana do
            rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Lublin –
            Wschód w Lublinie z siedzibą w Świdniku, VI Wydział Gospodarczy
            Krajowego Rejestru Sądowego jako sąd, w którym przechowywana jest
            dokumentacja Spółki pod numerem KRS: 0000036205, NIP: 821-20-25-575,
            Regon: 710529892, Kapitał zakładowy: 700 500 zł.
          </p>
          <p>
            b)
            <b>Usługobiorca/Pacjent</b> – osoba, która korzysta z Usługi „Wyniki
            bez rejestracji”, na rzecz której zostały wykonane badania
            laboratoryjne przez Usługodawcę lub Spółki z Grupy Centrum
            Medyczno-Diagnostyczne.
          </p>
          <p>
            c)
            <b>Serwis/Strona Dostępu</b> – strona https://pacjent.digitmed.pl.
          </p>
          <p>
            d)
            <b>Spółki z Grupy Centrum Medyczno-Diagnostyczne</b> – oznacza
            następujące spółki: Centrum Medyczno-Diagnostyczne sp.zo.o, Centrum
            Medyczne Józefów sp.zo.o., Ośrodek Medycyny Pracy sp.zo.o., MAG
            sp.zo.o..
          </p>
          <p>
            e)
            <b>Zlecenie</b> – unikalny numer zlecenia wygenerowany przez
            Usługodawcę podczas realizacji usługi diagnostycznej i podany do
            wiadomości pacjenta. Numer zlecenia jest jednocześnie loginem w
            usłudze „Wyniki bez rejestracji”.
          </p>
          <p>
            f)
            <b>Logowanie</b> – proces polegający na podaniu numeru Zlecenia w
            polu login i potwierdzeniu PESELem lub numerem dokumentu tożsamości
            Usługobiorcy podanym przy rejestracji (jeśli nie podano numeru
            PESEL)
          </p>
          <p>
            g)
            <b>System teleinformatyczny</b> – zespół współpracujących ze sobą
            urządzeń informatycznych i oprogramowania, zapewniający
            przetwarzanie i przechowywanie, a także wysyłanie i odbieranie
            danych poprzez sieci telekomunikacyjne za pomocą właściwego dla
            danego rodzaju sieci urządzenia końcowego w rozumieniu Ustawy z dnia
            16 lipca 2004 r. Prawo telekomunikacyjne, zwanej dalej Ustawa Prawo
            telekomunikacyjne.
          </p>
          <p>
            h)
            <b>Kanał dostępu</b> – połączenie poprzez sieć Internet z serwerem
            świadczącym Usługę „Wyniki bez rejestracji”.
          </p>
          <p>
            i)
            <b>Usługa „Wyniki bez rejestracji”</b> dalej zwana także Usługą –
            usługa wykonywana przez Centrum Medyczno-Diagnostyczne Sp. z o.o.
            poprzez wysyłanie i odbieranie danych za pomocą systemów
            teleinformatycznych na indywidualne żądanie Usługobiorcy, bez
            jednoczesnej obecności stron, przy czym dane te są transmitowane za
            pośrednictwem sieci publicznych w rozumieniu Ustawy Prawo
            telekomunikacyjne.
          </p>
          <p>
            <b>
              III. Zasady, zakres i warunki świadczenia Usługi „Wyniki bez
              rejestracji”:
            </b>
          </p>
          <p>
            1. Centrum Medyczno-Diagnostyczne, w ramach zlecenia usługi
            wykonania badania diagnostycznego w Centrum Medyczno-Diagnostycznym
            lub w Spółce z Grupy Centrum świadczy Usługę „Wyniki bez
            rejestracji”, polegającą na udostępnieniu na indywidualne żądanie
            Usługobiorcy wyników badań diagnostycznych wykonanych przez Centrum
            Medyczno-Diagnostyczne lub przez Spółkę z Grupy Centrum. Usługa
            „Wyniki Bez rejestracji” dostępna jest pod adresem
            https://pacjent.digitmed.pl.
          </p>
          <p>
            2. Wynik Usługobiorcy jest wyświetlany i może zostać pobrany na
            urządzenie w formacie podpisanego podpisem kwalifikowanym PDF.
          </p>
          <p>3. Wynik dostępny jest w języku polskim i języku angielskim.</p>
          <p>
            4. Usługa „Wyniki bez rejestracji” świadczona jest zgodnie z
            przepisami Ustawy z dnia 6 listopada 2008 r. o prawach pacjenta i
            Rzeczniku Praw Pacjenta oraz Rozporządzenia Ministra Zdrowia z dnia
            6 kwietnia 2020r. w sprawie rodzajów i zakresu dokumentacji
            medycznej oraz sposobu jej przetwarzania.
          </p>
          <p>
            5. Wymagania techniczne dotyczące korzystania z Usługi „Wyniki bez
            rejestracji” są następujące: a. urządzenie elektroniczne (min.
            komputer lub telefon) połączone z siecią Internet; b. przeglądarka
            internetowa umożliwiająca wyświetlanie na ekranie komputera
            dokumentów hipertekstowych (HTML) powiązanych w sieci Internet przez
            sieciową usługę WWW;
          </p>
          <p>
            6. Centrum Medyczno-Diagnostyczne Sp. z o.o. dołoży należytych
            starań, aby korzystanie z Usługi „Wyniki bez rejestracji” było
            możliwe dla użytkowników wszystkich popularnych przeglądarek
            internetowych, systemów operacyjnych, typów komputerów oraz typów
            połączeń internetowych. Jednakże Centrum Medyczno-Diagnostyczne Sp.
            z o.o. nie gwarantuje i nie ponosi odpowiedzialności za to, że każda
            kombinacja tych czynników umożliwia korzystanie z Usługi.
          </p>
          <p>
            7. Usługodawca zastrzega, że z uwagi na publiczny charakter sieci
            Internet, korzystanie z Usługi „Wyniki bez rejestracji” wiąże się z
            normalnym, w takiej sytuacji, ryzykiem.
          </p>
          <p>
            8. Korzystanie z Usługi „Wyniki bez rejestracji” możliwe jest przez
            24 godziny na dobę we wszystkie dni tygodnia.
          </p>
          <p>
            <b>IV. Korzystanie z Usługi „Wyniki Bez rejestracji”:</b>
          </p>
          <p>
            1. Korzystanie z Usługi „Wyniki Bez rejestracji” jest możliwe w
            przypadku wyników badań Pacjenta z pojedynczego zlecenia COVID-19.
          </p>
          <p>
            2. Usługobiorca jest zobowiązany do zachowania indywidualnego
            zlecenia w tajemnicy i nieprzekazywania ich osobom trzecim. Centrum
            Medyczno-Diagnostyczne nie ponosi odpowiedzialności z tytułu dostępu
            do Usługi „Wyniki bez rejestracji” przez osoby nieuprawnione, za
            wyjątkiem, gdy wynika to z zawinionego działania lub zaniechania
            Usługodawcy lub działania lub zaniechania osób, za pośrednictwem lub
            przy pomocy których Centrum Medyczno-Diagnostyczne wykonuje swoje
            zobowiązania wobec konsumenta.
          </p>
          <p>
            3. Aby skorzystać z usługi należy postępować zgodnie z instrukcją
            dostępną na stronie https://pacjent.digitmed.pl
          </p>
          <p>
            4. Usługa „Wyniki Bez rejestracji jest ważna przez 30 dni od dnia
            daty powstania wyniku. Po upływie tego terminu dostęp do wyniku jest
            możliwy na ogólnych zasadach określonych w Ustawie z dnia 6
            listopada 2008 r. o prawach pacjenta i Rzeczniku Praw Pacjenta oraz
            Rozporządzenia Ministra Zdrowia z dnia 6 kwietnia 2020 r. w sprawie
            rodzajów i zakresu dokumentacji medycznej oraz sposobu jej
            przetwarzania.
          </p>
          <p>
            5. Każdorazowo, dostęp do wyników badań jest ograniczony czasowo do
            10 minut, po czym następuje automatyczne wylogowanie Usługobiorcy.
          </p>
          <p>
            6. Usługobiorca może w każdej chwili zrezygnować z usługi „Wyniki
            Bez rejestracji” poprzez kliknięcie opcji Dezaktywuj konto. Po
            dezaktywacji usługi „Wyniki Bez rejestracji” Usługobiorca ma dostęp
            do wyników badań na ogólnych zasadach określonych w Ustawie z dnia 6
            listopada 2008 r. o prawach pacjenta i Rzeczniku Praw Pacjenta oraz
            Rozporządzenia Ministra Zdrowia z dnia 6 kwietnia 2020 r. w sprawie
            rodzajów i zakresu dokumentacji medycznej oraz sposobu jej
            przetwarzania.
          </p>
          <p>
            7. Z zastrzeżeniem, że Zaprzestanie korzystania z Usługi „Wyniki Bez
            rejestracji” nie oznacza usunięcia przechowywanych przez Usługodawcę
            wyników badań.
          </p>
          <p>
            <b>V. Prawa i obowiązki Usługodawcy i Usługobiorców:</b>
          </p>
          <p>
            1. Usługodawca zobowiązuje się do stałego i nieprzerwanego
            świadczenia Usługi „Wyniki Bez rejestracji”, z zastrzeżeniem ust. 2
            poniżej.
          </p>
          <p>
            2. Usługodawca zastrzega sobie prawo do czasowego zawieszenia
            dostępu do Usługi „Wyniki Bez rejestracji”: – ze względów
            bezpieczeństwa oraz jakichkolwiek innych przyczyn niezależnych od
            Usługodawcy; – z konieczności dokonania niezbędnych czynności
            związanych z prawidłowym funkcjonowaniem, ulepszaniem, konserwacją
            lub zabezpieczaniem systemów informatycznych i zasilania, przy czym
            okres czasowego zawieszenia dostępu nie będzie dłuższy niż czas
            konieczny do usunięcia powstałych nieprawidłowości.
          </p>
          <p>
            3. Centrum Medyczno-Diagnostyczne nie ponosi odpowiedzialności za
            problemy techniczne bądź ograniczenia techniczne występujące na
            sprzęcie komputerowym, z którego korzysta Usługobiorca, które
            uniemożliwiają Usługobiorcy korzystanie z Usługi „Wyniki Bez
            rejestracji”, jak również za ewentualne skutki wadliwego działania
            lub niezgodnego z prawem wykorzystania łączy telekomunikacyjnych
            znajdujących się poza dyspozycją Usługodawcy i szkody przez nie
            wywołane.
          </p>
          <p>
            4. Centrum Medyczno-Diagnostyczne nie ponosi również
            odpowiedzialności za: – problemy w funkcjonowaniu Usługi „Wyniki Bez
            rejestracji”, jeżeli nastąpiły one wskutek zdarzeń, takich jak m.in.
            wadliwe działanie sieci Internet, zakłócenia w działaniu sieci
            energetycznej, wady transmisji danych, działanie siły wyższej,
            decyzje organów władzy publicznej, opóźnienia wynikające z awarii
            systemów komputerowych, systemów zasilania i łączy
            telekomunikacyjnych, których Centrum Medyczno-Diagnostyczne przy
            zachowaniu należytej staranności nie była w stanie przewidzieć, lub
            którym nie mogło zapobiec; chyba że problemy w funkcjonowaniu usługi
            wystąpiły w wyniku działania lub zaniechania osób za pośrednictwem
            lub przy pomocy których Centrum Medyczno-Diagnostyczne wykonuje
            swoje zobowiązania wobec Usługobiorców; – następstwa korzystania z
            Usługi „Wyniki Bez rejestracji” przez Usługobiorcę niezgodnie z
            postanowieniami niniejszego Regulaminu; – korzystanie przez
            Usługobiorców z Usługi „Wyniki Bez rejestracji” w sposób sprzeczny z
            Regulaminem lub przepisami prawa; – utratę przez Usługobiorcę danych
            spowodowanych okolicznościami, za które Centrum
            Medyczno-Diagnostyczne nie ponosi odpowiedzialności, przy czym
            Centrum Medyczno-Diagnostyczne ponosić będzie odpowiedzialność,
            jeżeli okoliczności te wystąpiły w wyniku działania lub zaniechania
            osób, za pośrednictwem lub przy pomocy których Centrum
            Medyczno-Diagnostyczne wykonuje swoje zobowiązania wobec konsumenta.
          </p>
          <p>
            5. Usługobiorca zobowiązuje się korzystać z Usługi „Wyniki Bez
            rejestracji” w sposób zgodny z niniejszym Regulaminem, przepisami
            prawa i dobrymi obyczajami.
          </p>
          <p>
            6. Usługobiorca zobowiązuje się do niepodejmowania działań
            prowadzących do zachwiania stabilności systemów informatycznych
            Usługodawcy lub pogorszenia jakości świadczonej przez niego Usługi
            „Wyniki Bez rejestracji”. W celu zachowania bezpieczeństwa, Centrum
            Medyczno-Diagnostyczne przechowuje historię logowania Usługobiorcy.
          </p>
          <p>
            7. Usługodawca nie ponosi żadnej odpowiedzialności za jakiekolwiek
            skutki wykorzystania przez Usługobiorcę informacji uzyskanych za
            pośrednictwem Usługi „Wyniki Bez rejestracji”, w szczególności za
            konsekwencje podjętych na ich podstawie decyzji w zakresie ochrony
            zdrowia.
          </p>
          <p>
            <b>VI. Tryb postępowania reklamacyjnego:</b>
          </p>
          <p>
            1. Usługobiorca ma prawo złożenia reklamacji ściśle związanych z
            wadliwym funkcjonowaniem Usługi „Wyniki Bez rejestracji”. Złożenie
            reklamacji może nastąpić pisemnie na adres siedziby Usługodawcy.
          </p>
          <p>
            2. Reklamacja powinna zawierać co najmniej następujące dane: a.
            oznaczenie Usługobiorcy (w tym jego imię, nazwisko, adres pocztowy
            lub adres e-mail, numer Karty lub numer zlecenia  w wypadku wyników
            z pojedynczego zlecenia); b. wskazanie problemu, w związku z którym
            składana jest reklamacja.
          </p>
          <p>
            3. Usługodawca rozpatrzy reklamację niezwłocznie po jej otrzymaniu i
            udzieli informacji pisemnie lub poprzez e-mail, nie później niż w
            terminie 30 dni od dnia jej otrzymania. Usługodawca ma prawo odmówić
            wykonania usług niezwiązanych przedmiotowo z korzystaniem z Usługi
            „Wyniki Bez rejestracji”, takich jak: skanowanie antywirusowe
            komputera, instalacja sterowników drukarki, konfigurowanie
            parametrów łącza internetowego, testowanie poprawności pracy sprzętu
            komputerowego lub jego podzespołów, aktualizacja systemu
            operacyjnego.
          </p>
          <p>
            <b>VII. Polityka prywatności:</b>
          </p>
          <p>
            1. Centrum Medyczno-Diagnostyczne zapewnia poufność przesyłanych
            danych poprzez zastosowanie odpowiednich narzędzi informatycznych
            oraz szyfrowania.
          </p>
          <p>
            2. Usługodawca przywiązuje dużą wagę do kwestii zachowania
            prywatności Usługobiorców i ochrony ich danych osobowych. Dane te są
            szczególnie chronione i zabezpieczone przed dostępem osób
            nieupoważnionych. Usługodawca zapewnia Usługobiorcom realizację
            uprawnień wynikających z przepisów dotyczących ochrony danych
            osobowych, w szczególności prawo wglądu do własnych danych, prawo
            żądania aktualizacji danych oraz prawo wniesienia sprzeciwu w
            przypadkach określonych w przepisach tej ustawy.
          </p>
          <p>
            3. Administratorem danych osobowych jest Centrum Medyczno
            -Diagnostyczne.
          </p>
          <p>
            4. W wyniku współpracy Usługodawcy ze Spółkami z Grupy Centrum
            Medyczno-Diagnostyczne, polegającej na udostępnieniu bez rejestracji
            wyników badań laboratoryjnych, Administratorem danych są Spółki z
            Grupy Centrum, w których osoby wykonały badania laboratoryjne.
            Centrum Medyczno-Diagnostyczne przetwarza dane pacjentów Spółek z
            Grupy Centrum wyłącznie w celu realizacji usługi „Wyniki bez
            rejestracji”.
          </p>
          <p>
            5. Administrator danych osobowych powołał Inspektora Ochrony Danych
            w celu nadzorowania przestrzegania przepisów ochrony danych. Kontakt
            z Inspektorem Ochrony Danych możliwy jest pod adresem e-mail:
            iod@centrum.med.pl
          </p>
          <p>
            6. Podanie danych osobowych jest dobrowolne, jednak niepodanie
            danych uniemożliwia korzystanie z niniejszej usługi.
          </p>
          <p>
            7. Przetwarzanie danych odbywa się w celu udostępnienia dokumentacji
            medycznej pacjenta w formie elektronicznej. Przetwarzanie danych
            odbywa się w związku ze świadczeniem usług zdrowotnych, zarządzaniem
            tymi usługami, świadczeniem dostępu do wyników realizowanych badań.
            Zakres przetwarzanych danych i okres ich przechowywania wynika z
            aktualnie obowiązujących przepisów prawa, tj. ustawy z dnia 6
            listopada 2008 r. o prawach pacjenta i Rzeczniku Praw Pacjenta i
            rozporządzenia Rady Ministrów z dnia 26 listopada 2020 r. w sprawie
            ustanowienia określonych ograniczeń, nakazów i zakazów w związku z
            wystąpieniem stanu epidemii. Zgodnie z nimi podmiot udzielający
            świadczeń zdrowotnych przechowuje dokumentację medyczną przez okres
            20 lat, licząc od końca roku kalendarzowego, w którym dokonano
            ostatniego wpisu, za wyjątkiem sytuacji przewidzianych w przepisach
            prawa. Dokumentacja medyczna zawiera co najmniej: nazwisko i imię
            (imiona), datę urodzenia, oznaczenie płci, adres miejsca
            zamieszkania, numer PESEL, a w przypadku osób, które nie mają
            nadanego numeru PESEL – rodzaj i numer dokumentu potwierdzającego
            tożsamość, numer telefonu kontaktowego.
          </p>
          <p>
            8. Odbiorcami danych będą podmioty realizujące badania diagnostyczne
            w imieniu administratora danych, jak i podmioty dostarczające
            obsługujące i serwisujące systemy informatyczne, w których dane
            osoby są przetwarzane.
          </p>
          <p>9. Dane osobowe nie będą przekazywane do państw trzecich.</p>
          <p>
            10. Osoba, której dane dotyczą posiada prawo dostępu do treści
            swoich danych oraz ich poprawiania. W tym celu powinna wysłać
            żądanie na adres e-mail: iod@centrum.med.pl
          </p>
          <p>
            11. Jeśli osoba, której dane dotyczą uzna, że przetwarzanie jej
            danych jest niezgodne z aktualnie obowiązującymi przepisami ochrony
            danych, ma prawo do wniesienia skargi do organu nadzorczego.
          </p>
          <p>
            12. Po zakończeniu korzystania z Usługi „Wyniki Bez rejestracji”
            przez Usługobiorcę, Centrum Medyczno-Diagnostyczne nie będzie
            przetwarzać danych osobowych Usługobiorców, za wyjątkiem tych
            danych, które są: – niezbędne do wyjaśnienia okoliczności
            niedozwolonego korzystania z Usługi; – dopuszczone do przetwarzania
            na podstawie odrębnych przepisów lub umowy.
          </p>
          <p>
            <b>VIII. Ochrona praw własności intelektualnej:</b>
          </p>
          <p>
            1. Prawa wyłączne do treści udostępnianych w ramach Usługi „Wyniki
            Bez rejestracji”, w szczególności prawa autorskie, prawo odrębne do
            baz danych, prawa z rejestracji znaków towarowych, przysługują
            Centrum Medyczno-Diagnostycznemu oraz podmiotom, z którymi Centrum
            Medyczno-Diagnostyczne zawarło stosowne umowy.
          </p>
          <p>
            2. Usługobiorca zobowiązany jest przestrzegać przepisów prawa
            własności intelektualnej. Wszelkie kopiowanie, wprowadzanie zmian, a
            także publiczne odtwarzanie udostępnianych treści bez zgody Centrum
            Medyczno-Diagnostycznemu jest zakazane, o ile nie wynika to z
            odrębnych umów lub bezwzględnie obowiązujących przepisów prawa.
          </p>
          <p>
            <b>IX. Cookies:</b>
          </p>
          <p>
            1. W trakcie świadczenia Usługi, Centrum Medyczno-Diagnostyczne
            korzysta z plików cookies.
          </p>
          <p>
            2. Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w
            szczególności pliki tekstowe, które przechowywane są w urządzeniu
            końcowym Pacjentów z przeznaczeniem polepszenia jakości korzystania
            ze Stron Dostępu.
          </p>
          <p>
            3. Podmiotem zamieszczającym na urządzeniu końcowym Pacjentów pliki
            cookies oraz uzyskującym do nich dostęp jest Usługodawca lub
            podmioty świadczące usługi informatyczne na rzecz Centrum
            Medyczno-Diagnostyczne.
          </p>
          <p>
            4. Oprogramowanie do przeglądania stron internetowych (przeglądarka
            internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików
            cookies w urządzeniu końcowym Pacjentów.
          </p>
          <p>
            5. Pacjenci mogą dokonać zmiany ustawień w tym zakresie.
            Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe
            jest także automatyczne blokowanie plików cookies. Szczegółowe
            informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki
            internetowej.
          </p>
          <p>
            6. Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre
            funkcjonalności dostępne na stronach internetowych systemu.
          </p>
          <p>
            7. Zalecane jest zapoznanie się z polityką ochrony prywatności tych
            firm, aby poznać zasady korzystania z plików cookies wykorzystywane
            w statystykach: Polityka ochrony prywatności Google Analytics.
          </p>
          <p>
            8. W zakresie informacji o preferencjach użytkownika gromadzonych
            przez sieć reklamową Google, użytkownik może przeglądać i edytować
            informacje wynikające z plików cookies przy pomocy narzędzia:
            https://www.google.com/ads/preferences/.
          </p>
          <p>
            9. Informacje o niektórych zachowaniach użytkowników podlegają
            logowaniu w warstwie serwerowej. Dane te są wykorzystywane wyłącznie
            w celu administrowania serwisem oraz w celu zapewnienia jak
            najbardziej sprawnej obsługi świadczonych usług hostingowych.
          </p>
          <p>
            10. Przeglądane zasoby identyfikowane są poprzez adresy URL. Ponadto
            zapisowi mogą podlegać: – czas nadejścia zapytania, – czas wysłania
            odpowiedzi, – nazwę stacji klienta – identyfikacja realizowana przez
            protokół HTTP, – informacje o błędach, jakie nastąpiły przy
            realizacji transakcji HTTP, – adres URL strony poprzednio
            odwiedzanej przez Pacjentów (referer link), – informacje o
            przeglądarce Pacjenta, – Informacje o adresie IP.
          </p>
          <p>
            11. Dane powyższe nie są kojarzone z konkretnymi osobami
            przeglądającymi Strony Dostępu.
          </p>
          <p>
            12. Dane powyższe są wykorzystywane jedynie dla celów
            administrowania serwerem.
          </p>
          <p>
            13. Jeśli Pacjent nie chce otrzymywać plików cookies, może zmienić
            ustawienia przeglądarki z zastrzeżeniem, że wyłączenie obsługi
            plików cookies niezbędnych dla procesów uwierzytelniania,
            bezpieczeństwa, utrzymania preferencji użytkownika może utrudnić, a
            w skrajnych przypadkach może uniemożliwić korzystanie ze stron www.
          </p>
          <p>
            <b>X. Postanowienia końcowe:</b>
          </p>
          <p>
            1. Centrum Medyczno-Diagnostyczne zastrzega sobie prawo do
            jednostronnego decydowania o zawartości Serwisu, dokonywania w nim
            zmian oraz modyfikacji, bez konieczności uprzedniego informowania
            Usługobiorców.
          </p>
          <p>
            2. Usługodawca zastrzega sobie prawo zmiany niniejszego Regulaminu w
            przypadku wystąpienia co najmniej jednej ze wskazanych ważnych
            przyczyn: a) zmiany profilu świadczonych usług, o ile zmiany takie
            nie będą prowadziły do zwiększenia obciążeń Pacjentów i nie będą
            naruszały interesów; b) zmiany możliwości technicznych oraz c)
            zmiany przepisów prawa; w zakresie, w jakim powyższe zdarzenia mają
            wpływ na warunki świadczenia przez Centrum Medyczno-Diagnostyczne
            usług określonych w Regulaminie lub postanowienia Regulaminu
          </p>
          <p>
            3. Usługobiorcy będą związani postanowieniami nowego Regulaminu, o
            ile nie wypowiedzą go w terminie 14 dni od dnia powiadomienia o
            zmianie Regulaminu.
          </p>
          <p>4. Prawem właściwym jest prawo polskie.</p>
          <p>
            5. W przypadku rozbieżności pomiędzy niniejszym Regulaminem, a
            postanowieniami zawartych przez Centrum Medyczno-Diagnostyczne
            indywidualnych umów dotyczących świadczenia Usługi „Wyniki Bez
            rejestracji”, pierwszeństwo mają postanowienia tych umów.
          </p>
        </section>
        <footer
          v-if="!isRegulationsAccepted"
          class="modal-card-foot buttons is-centered"
        >
          <div>
            <b-button
              icon-left="check-underline"
              type="is-success"
              @click="saveTermsAgreement()"
              size="is-small"
              >Akceptuj</b-button
            >
            <b-button
              icon-left="close"
              type="is-danger"
              @click="isModalTermsActive = false"
              size="is-small"
              >Anuluj</b-button
            >
          </div>
        </footer>
      </div>
    </b-modal>
    <b-modal
      :active.sync="isModalInstructionActive"
      has-modal-card
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      :full-screen="isMobile"
    >
      <div class="modal-card">
        <header class="modal-card-head has-background-kin">
          <p
            class="modal-card-title has-text-primary"
            style="font-size: 1.3rem"
          >
            <b-icon
              icon="file-document-multiple"
              class="mr-2 has-text-primary"
            ></b-icon
            >Instrukcja odbioru wyników
          </p>
          <button
            type="button"
            class="delete"
            @click="isModalInstructionActive = false"
          />
        </header>
        <section class="modal-card-body">
          <img src="../assets/001.png" width="350" />
          <p>
            <b>Krok 1.</b> Podaj numer zlecenia, który znajduje się na etykiecie
            otrzymanej w punkcie pobrań.
          </p>
          <br />
          <p>
            <b>Krok 2.</b> Podaj numer PESEL lub numer dokumentu tożsamości (w
            przypadku braku numeru PESEL).
          </p>
          <br />
          <p>
            <b>Krok 3.</b> Kliknij przycisk "Sprawdź wyniki". Jeśli podane
            zostały prawidłowe dane, nastąpi przekierowanie do strony z wynikami
            badań.
          </p>
          <br />
          <p>
            <b>Krok 4.</b> Po wejściu na stronę zostaną wyświetlone dane badań
            oraz ich wyniki.
          </p>
          <br />
          <img src="../assets/002.png" width="200" />
          <p class="mb-4">
            <b>Krok 5.</b> Możliwe jest także pobranie wyniku w formie dokumentu
            podpisanego kwalifikowanym podpisem elektronicznym. Aby pobrać
            dokument kliknij "Pobierz wynik (PDF)". Ze względów bezpieczeństwa
            po 10 minutach nastąpi automatyczne wylogowanie z portalu.
          </p>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>

import axios from 'axios'
import { Apis } from '../config_apis'
import PatientPortalDashboard from './patient-portal/PatientPortalDashboard'

export default {
  name: 'DataSearch',
  mixins: [],
  components: {
    PatientPortalDashboard
  },

  data: function () {
    return {
      pesel: '',
      id: '',
      login: '',
      pass: '',
      st: 0,

      pl: "PL",
      eng: "ENG",

      result: null,

      loading: false,

      cc: 600,
      myInterval: null,

      isRegulationsAccepted: false,

      isModalTermsActive: true,
      isModalInstructionActive: false,

    }
  },

  mounted: function () {
    this.checkTermsAgreement()
  },

  computed: {

    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },

    timer() {
      let cc = this.cc
      let minutes = Math.floor(cc / 60)
      let seconds = cc - minutes * 60
      let minString = ''
      let secString = ''
      let secRemainder10 = seconds % 10

      if (minutes > 4 || minutes == 0) {
        minString = minutes + ' minut'
      } else if (minutes > 1) {
        minString = minutes + ' minuty'
      } else {
        minString = minutes + ' minutę'
      }

      if (seconds == 1) {
        secString = seconds + ' sekundę'
      } else if ((seconds < 10 || seconds > 20) && secRemainder10 > 1 && secRemainder10 < 5) {
        secString = seconds + ' sekundy'
      } else {
        secString = seconds + ' sekund'
      }

      if (minutes == 0) {
        return secString
      } else if (seconds == 0) {
        return minString
      } else {
        return minString + ' ' + secString
      }

    }
  },

  methods: {
    saveTermsAgreement() {
      this.isModalTermsActive = false;
      this.isRegulationsAccepted = true;
      localStorage.setItem('szokTermsAgreement', 'true')
    },
    checkTermsAgreement() {
      if (localStorage.getItem('szokTermsAgreement') === 'true') {
        this.isModalTermsActive = false;
        this.isRegulationsAccepted = true;
      }
    },
    logout() {
      clearInterval(this.myInterval)
      this.cc = 600
      this.st = 0
    },
    counter() {
      if (this.cc == 0) {
        clearInterval(this.myInterval)
        this.st = 0
      } else {
        this.cc--
      }
    },

    getResult() {
      this.loading = true
      let object = {
        login: this.id,
        password: this.pesel
      }
      axios
        .post(Apis.PATIENT_GET_RESULT, object)
        .then(response => {
          if (response.data.labPatientPortalResultStatus === 1) {
            if (!response.data.order) {
              this.$buefy.snackbar.open({
                message: 'Badanie jest w trakcie realizacji. Wynik zostanie udostępniony w ciągu 24 godzin od momentu dostarczenia próbki do laboratorium.',
                type: 'is-warning',
                position: 'is-top',
                duration: 10000
              })
              this.pesel = ''
              this.id = ''
              this.login = ''
              this.pass = ''
            } else {
              this.result = response.data
              this.cc = 600
              this.myInterval = setInterval(this.counter, 1000)
              this.st = 1
              this.pesel = ''
              this.id = ''
              this.login = ''
              this.pass = ''
            }
          } else if (response.data.labPatientPortalResultStatus === 2) {
            this.result = response.data
            this.cc = 600
            this.myInterval = setInterval(this.counter, 1000)
            this.st = 1
            this.pesel = ''
            this.id = ''
            this.login = ''
            this.pass = ''
          }
          this.loading = false
        })
        .catch(error => {
          if (error) {
            this.$buefy.snackbar.open({
              message: 'Nieprawidłowy numer zlecenia, PESEL lub numer dokumentu',
              type: 'is-danger',
              position: 'is-top',
            })
            this.loading = false
          }
        })
    },

    getResultWithToken() {


      this.$buefy.dialog.prompt({
        message: `Podaj kod jednorazowy, który wysłaliśmy na numer telefonu zarejestrowany przy pobraniu.`,
        inputAttrs: {
          maxlength: 6
        },
        trapFocus: true,
        onConfirm: (token) => {
          this.loading = true
          let object = {
            login: this.id,
            password: this.pesel,
            token: token
          }
          axios
            .post(Apis.PATIENT_GET_RESULT, object)
            .then(response => {
              if (response.data.labPatientPortalResultStatus === 1) {
                if (!response.data.order) {
                  this.$buefy.snackbar.open({
                    message: 'Badanie jest w trakcie realizacji. Wynik zostanie udostępniony w ciągu 24 godzin od momentu dostarczenia próbki do laboratorium.',
                    type: 'is-warning',
                    position: 'is-top',
                    duration: 10000
                  })
                  this.pesel = ''
                  this.id = ''
                  this.login = ''
                  this.pass = ''
                } else {
                  this.result = response.data
                  this.cc = 600
                  this.myInterval = setInterval(this.counter, 1000)
                  this.st = 1
                  this.pesel = ''
                  this.id = ''
                  this.login = ''
                  this.pass = ''
                }
              } else if (response.data.labPatientPortalResultStatus === 2) {
                this.result = response.data
                this.cc = 600
                this.myInterval = setInterval(this.counter, 1000)
                this.st = 1
                this.pesel = ''
                this.id = ''
                this.login = ''
                this.pass = ''
              }
              this.loading = false
            })
            .catch(error => {
              if (error) {
                this.$buefy.snackbar.open({
                  message: 'Nieprawidłowy numer zlecenia, PESEL lub numer dokumentu',
                  type: 'is-danger',
                  position: 'is-top',
                })
                this.loading = false
              }
            })
        }
      })
    },

    getFile(string, lang) {
      this.loading = true
      let obj = {
        downloadLink: string,
      }
      var fileDownload = require('js-file-download');
      axios({
        method: 'post',
        responseType: 'blob',
        url: Apis.PATIENT_GET_FILE,
        data: obj
      }).then(response => {
        this.loading = false
        fileDownload(response.data, this.result.sampleIntCode + "_" + lang + '.pdf');
      })
        .catch(error => {
          if (error) {
            this.$buefy.snackbar.open({
              message: 'Błąd pobierania pliku. Spróbuj pobrać plik po ponownym zalogowaniu.',
              type: 'is-danger',
              position: 'is-top',
            })
            this.loading = false
          }
        })
    },
    getResultDescription(num) {
      switch (num) {
        case "NEGATYWNY":
          return "Wynik negatywny oznacza, że nie wykryto żadnego z badanych genów (ORF 1ab oraz N) przy obecności sygnału kontroli wewnętrznej reakcji (IC). Wynik negatywny nie wyklucza obecności wirusa SARS-CoV-2 w przypadku, gdy liczba kopii RNA wirusa jest poniżej czułości metody (np. wczesny lub późny etap infekcji)."
        case "POZYTYWNY":
          return "Wynik pozytywny oznacza, że wykryto dwa badane geny (ORF 1ab oraz N). Wykrycie wskazanych genów świadczy o obecności wirusa SARS-CoV-2 w badanej próbce."
        case "NIEROZSTRZYGAJACY":
          return "Wynik nierozstrzygający oznacza, że wykryto tylko jeden z dwóch badanych genów (ORF 1ab lub N). Wynik nierozstrzygający może wynikać z granicznego dla testu poziomu wiremii (liczby kopii wirusa SARS-CoV-2) w badanej próbce (np. wczesny lub późny etap infekcji). Wynik nie wyklucza obecności wirusa SARS-CoV-2 i wymaga powtórzenia badania z niezależnego pobrania. Z powodów epidemiologicznych, do czasu uzyskania wyniku powtórnego pobrania zaleca się postępowanie jak w przypadku wyniku pozytywnego."
        case "NIEDIAGNOSTYCZNY":
          return "Wynik niediagnostyczny oznacza, że nie wykryto żadnego z badanych genów (ORF 1ab, N) przy jednoczesnym braku sygnału kontroli wewnętrznej reakcji (IC). Brak sygnału dla kontroli wewnętrznej reakcji może świadczyć o obecności inhibitorów reakcji PCR. Wynik niediagnostyczny nie wyklucza obecności wirusa SARS-CoV-2. Badanie wymaga powtórzenia z niezależnego pobrania."
      }
    },

    openPatientPortal() {
      this.st = 2
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.primary-color {
  color: #4ca1d7 !important;
}
.fs-105 {
  font-size: 1.05rem;
}
.fs-09 {
  font-size: 0.9rem;
}
.borderR {
  border: 1px solid red;
}
.vam {
  vertical-align: middle;
}
.lab-results-regulations-modal .modal-card {
  width: 100%;
}
.lab-results-regulations-modal .animation-content {
  max-width: 82% !important;
  width: 82%;
}
</style>

<template>
  <div class="m-4 has-text-left">
    <div class="columns">
      <div class="column is-narrow">
        <div class="box">
          <b-menu>
            <b-menu-list icon="home" label="Strona główna">
              <b-menu-item
                disabled
                @click="view = 0"
                :active="view === 0"
                icon="home"
                label="Strona główna"
              ></b-menu-item>
            </b-menu-list>
            <b-menu-list icon="microsoft-xbox-controller-menu" label="Pacjent">
              <b-menu-item
                @click="view = 1"
                icon="calendar-check"
                label="Umówione wizyty"
              ></b-menu-item>
              <b-menu-item
                @click="view = 2"
                icon="pill"
                label="Recepty"
              ></b-menu-item>
              <b-menu-item
                @click="view = 3"
                icon="file-document-check-outline"
                label="Skierowania"
              ></b-menu-item>
              <b-menu-item
                @click="view = 4"
                icon="text-box-check-outline"
                label="Zwolnienia"
              ></b-menu-item>
              <!-- <b-menu-item
                @click="view = 6"
                icon="text-box-outline"
                label="Historia"
              ></b-menu-item> -->
              <!-- <b-menu-item
                @click="view = 7"
                icon="order-bool-ascending-variant"
                label="Ankiety"
              ></b-menu-item> -->
            </b-menu-list>
            <!-- <b-menu-list icon="teach" label="Edukacja">
              <b-menu-item
                @click="view = 8"
                icon="teach"
                label="Edukacja"
              ></b-menu-item>
            </b-menu-list> -->
            <b-menu-list icon="text-box-search-outline" label="Wyniki badań">
              <b-menu-item
                @click="view = 12"
                icon="text-box-search-outline"
                label="Wyniki"
              ></b-menu-item>
            </b-menu-list>
            <b-menu-list icon="card-account-phone-outline" label="Kontakt">
              <b-menu-item
                disabled
                icon="card-account-phone-outline"
                label="Kontakt"
              ></b-menu-item>
            </b-menu-list>
            <!-- <b-menu-list icon="cog-outline" label="Ustawienia">
              <b-menu-item
                disabled
                icon="cog-outline"
                label="Ustawienia"
              ></b-menu-item>
            </b-menu-list> -->
          </b-menu>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div v-if="view == 1">
            <b-table
              :data="appHistory"
              focusable
              hoverable
              narrowed
              style="font-size: 0.9rem"
            >
              <!-- <b-table-column centered>
                <template v-slot:header="{}"> Numer recepty </template>
                <template v-slot="props">
                  {{ props.row.numerRecepty }}
                </template>
              </b-table-column> -->
              <!-- <b-table-column centered width="1rem">
                <template v-slot:header="{}"> PIN </template>
                <template v-slot="props">
                  {{ props.row.pin }}
                </template>
              </b-table-column> -->
              <b-table-column centered>
                <template v-slot:header="{}"> Data wizyty </template>
                <template v-slot="props">
                  {{ props.row.dataWizyty }}
                </template>
              </b-table-column>
              <b-table-column centered>
                <template v-slot:header="{}"> Typ wizyty </template>
                <template v-slot="props">
                  {{ props.row.typWizyty }}
                </template>
              </b-table-column>
              <b-table-column centered>
                <template v-slot:header="{}"> Miejsce wizyty </template>
                <template v-slot="props">
                  {{ props.row.przychodnia }}
                </template>
              </b-table-column>
              <b-table-column centered>
                <template v-slot:header="{}"> Specjalista </template>
                <template v-slot="props">
                  {{ props.row.lekarz }}
                </template>
              </b-table-column>
              <b-table-column centered>
                <template v-slot:header="{}"> Dodatkowe informacje </template>
                <template v-slot="props">
                  {{ props.row.dodatkoweInformacje }}
                </template>
              </b-table-column>
              <b-table-column label="Akcje" centered>
                <div
                  style="display: flex; justify-content: center"
                  class="pl-1"
                >
                  <b-tooltip label="Wydrukuj rezerwację" type="is-primary">
                    <b-button
                      class="mr-1"
                      size="is-small"
                      type="is-primary"
                      disabled
                      >Wydrukuj rezerwację
                      <!-- <b-icon
                        type="is-primary"
                        icon="printer"
                        class="vam"
                      ></b-icon> -->
                    </b-button>
                  </b-tooltip>
                  <b-tooltip label="Odwołaj rezerwację" type="is-danger">
                    <b-button
                      class="mr-1"
                      size="is-small"
                      type="is-danger"
                      disabled
                      >Odwołaj
                      <!-- <b-icon
                        type="is-primary"
                        icon="printer"
                        class="vam"
                      ></b-icon> -->
                    </b-button>
                  </b-tooltip>
                </div>
              </b-table-column>
              <!-- <template slot="detail" slot-scope="props">
                <pre>{{ props.row }}</pre>
              </template> -->
            </b-table>
          </div>
          <div v-if="view == 2">
            <b-table
              :data="ercHistory"
              focusable
              hoverable
              narrowed
              style="font-size: 0.9rem"
            >
              <b-table-column centered>
                <template v-slot:header="{}"> Numer recepty </template>
                <template v-slot="props">
                  {{ props.row.numerRecepty }}
                </template>
              </b-table-column>
              <b-table-column centered width="1rem">
                <template v-slot:header="{}"> PIN </template>
                <template v-slot="props">
                  {{ props.row.pin }}
                </template>
              </b-table-column>
              <b-table-column centered>
                <template v-slot:header="{}"> Data wystawienia </template>
                <template v-slot="props">
                  {{ props.row.dataWystawienia }}
                </template>
              </b-table-column>
              <b-table-column centered>
                <template v-slot:header="{}"> Data realizacji </template>
                <template v-slot="props">
                  {{ props.row.dataRealizacji }}
                </template>
              </b-table-column>
              <b-table-column label="Akcje" centered>
                <div
                  style="display: flex; justify-content: center"
                  class="pl-1"
                >
                  <b-tooltip label="Wydrukuj eReceptę" type="is-primary">
                    <b-button
                      class="mr-1"
                      size="is-small"
                      type="is-primary"
                      disabled
                      >Wydrukuj eReceptę
                      <!-- <b-icon
                        type="is-primary"
                        icon="printer"
                        class="vam"
                      ></b-icon> -->
                    </b-button>
                  </b-tooltip>
                </div>
              </b-table-column>
              <!-- <template slot="detail" slot-scope="props">
                <pre>{{ props.row }}</pre>
              </template> -->
            </b-table>
          </div>
          <div v-if="view == 3">
            <b-table
              :data="eskrHistory"
              focusable
              hoverable
              narrowed
              style="font-size: 0.9rem"
            >
              <b-table-column centered>
                <template v-slot:header="{}"> Numer recepty </template>
                <template v-slot="props">
                  {{ props.row.numerSkierowania }}
                </template>
              </b-table-column>
              <b-table-column centered width="1rem">
                <template v-slot:header="{}"> PIN </template>
                <template v-slot="props">
                  {{ props.row.pin }}
                </template>
              </b-table-column>
              <b-table-column centered>
                <template v-slot:header="{}"> Data wystawienia </template>
                <template v-slot="props">
                  {{ props.row.dataWystawienia }}
                </template>
              </b-table-column>
              <b-table-column centered>
                <template v-slot:header="{}"> Data realizacji </template>
                <template v-slot="props">
                  {{ props.row.dataRealizacji }}
                </template>
              </b-table-column>
              <b-table-column label="Akcje" centered>
                <div
                  style="display: flex; justify-content: center"
                  class="pl-1"
                >
                  <b-tooltip label="Wydrukuj eSkierowanie" type="is-primary">
                    <b-button
                      class="mr-1"
                      size="is-small"
                      type="is-primary"
                      disabled
                      >Wydrukuj eSkierowanie
                      <!-- <b-icon
                        type="is-primary"
                        icon="printer"
                        class="vam"
                      ></b-icon> -->
                    </b-button>
                  </b-tooltip>
                </div>
              </b-table-column>
              <!-- <template slot="detail" slot-scope="props">
                <pre>{{ props.row }}</pre>
              </template> -->
            </b-table>
          </div>
          <div v-if="view == 4">
            <b-table
              :data="zlaHistory"
              focusable
              hoverable
              narrowed
              style="font-size: 0.9rem"
            >
              <b-table-column centered>
                <template v-slot:header="{}"> Numer zwolnienia </template>
                <template v-slot="props">
                  {{ props.row.numerZwolnienia }}
                </template>
              </b-table-column>
              <!-- <b-table-column centered width="1rem">
                <template v-slot:header="{}"> PIN </template>
                <template v-slot="props">
                  {{ props.row.pin }}
                </template>
              </b-table-column> -->
              <b-table-column centered>
                <template v-slot:header="{}"> Data wystawienia </template>
                <template v-slot="props">
                  {{ props.row.dataWystawienia }}
                </template>
              </b-table-column>
              <b-table-column centered>
                <template v-slot:header="{}"> Data od ... do ... </template>
                <template v-slot="props">
                  {{ props.row.dataRealizacji }}
                </template>
              </b-table-column>
              <b-table-column label="Akcje" centered>
                <div
                  style="display: flex; justify-content: center"
                  class="pl-1"
                >
                  <b-tooltip label="Wydrukuj eSkierowanie" type="is-primary">
                    <b-button
                      class="mr-1"
                      size="is-small"
                      type="is-primary"
                      disabled
                      >Wydrukuj eZwolnienie
                      <!-- <b-icon
                        type="is-primary"
                        icon="printer"
                        class="vam"
                      ></b-icon> -->
                    </b-button>
                  </b-tooltip>
                </div>
              </b-table-column>
              <!-- <template slot="detail" slot-scope="props">
                <pre>{{ props.row }}</pre>
              </template> -->
            </b-table>
          </div>
          <div v-if="view == 12" style="font-size: 0.9rem">
            Brak wyników badań.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'PatientPortalDashboard',
  mixins: [],
  components: {

  },

  data: function () {
    return {
      view: 1,
      ercHistory: [
        {
          numerRecepty: "4753745973458643759347547",
          pin: "0000",
          dataWystawienia: "10.11.2023",
          dataRealizacji: "10.12.2023 - 10.01.2024"
        }
      ],
      eskrHistory: [
        {
          numerSkierowania: "4753745973458643759347547",
          pin: "0000",
          dataWystawienia: "10.11.2023",
          dataRealizacji: "10.12.2023 - 10.01.2024"
        }
      ],
      zlaHistory: [
        {
          numerZwolnienia: "ZRTR8947865",
          dataWystawienia: "10.10.2023",
          dataRealizacji: "10.10.2023 - 14.10.2024"
        }
      ],
      appHistory: [
        {
          dataWizyty: "13.10.2023",
          przychodnia: "Przychodnia nr 4, Siedlce",
          typWizyty: "Kompleksowa (POZ)",
          lekarz: "Grzegorz Kowalski",
          dodatkoweInformacje: "nr pokoju: 23"
        }
      ]
    }
  },

  mounted: function () {

  },

  computed: {

  },

  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
